import './Home.scss';

import { Button, Grid } from '@material-ui/core';
import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';

import CashbackCounter from '../../components/CashbackCounter/CashbackCounter';
import Hero from '../../components/Hero/Hero';
import HeroSearch from '../../components/HeroSearch/HeroSearch';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import InputWithButton from '../../components/InputWithButton/InputWithButton';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ShopTile from '../../components/ShopTile/ShopTile';
import SpecialTile from '../../components/SpecialTile/SpecialTile';
import TeaserTile from '../../components/TeaserTile/TeaserTile';
import Shop from '../../interfaces/shop';
import Special from '../../interfaces/special';
import { Alert } from '../../services/alert';
import Auth from '../../services/auth';
import helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import NewsletterManager from '../../services/manager/NewsletterManager';
import { CONSTANTS } from '../../services/constants';

interface Props {
  history: any;
}

interface State {
  cashbackCounterValue: number;
  cashbackShops: Shop[];
  specials: Special[];
  loading: boolean;
  redirectToSearch: boolean;
  query: string;
  newsletterEmail: string;
}

export class Home extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      cashbackCounterValue: 0,
      cashbackShops: [],
      specials: [],
      loading: true,
      redirectToSearch: false,
      query: '',
      newsletterEmail: '',
    };

    this.handleFavoriteChange = this.handleFavoriteChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChangeQuery = this.handleChangeQuery.bind(this);
    this.handleChangeNewsletterEmail = this.handleChangeNewsletterEmail.bind(
      this
    );
    this.handleSubscribeNewsletter = this.handleSubscribeNewsletter.bind(this);
    this.fetchCashbackData = this.fetchCashbackData.bind(this);
    this.setCounter = this.setCounter.bind(this);
  }

  handleFavoriteChange(shop: Shop) {
    return async (isFavorite: boolean) => {
      // Update shop
      if (!isFavorite) {
        await CashbackManager.removeShopFavorite(shop.id);
      } else {
        await CashbackManager.setShopFavorite(shop.id);
      }

      // Update view
      const shops = this.state.cashbackShops;
      shops.forEach((s: Shop) => {
        if (s.id === shop.id) {
          s.isFavorite = isFavorite;
        }
      });
      this.setState({
        cashbackShops: shops,
      });
    };
  }

  fetchCashbackData = async () => {
    const [cashbackShops, specials] = await Promise.all([
      CashbackManager.findNewestShops(1, 4),
      CashbackManager.findSpecials(),
    ]);

    if (cashbackShops && typeof cashbackShops.items === 'object')
      cashbackShops.items = Object.values(cashbackShops.items);

    this.setState({
      cashbackShops: cashbackShops.items,
      specials: specials.slice(0, 2),
    });
  };

  setCounter = async () => {
    const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
    const NEGATIVE_OFFSET_IN_DAYS = DAY_IN_MILLISECONDS * 35;

    let cashbackCounterValue = 0;

    try {
      const cashbackCounterData = await CashbackManager.getCashbackCounterData();
      if (cashbackCounterData) {
        const cashbackCounterIncPerDay = parseFloat(
          `${cashbackCounterData.increasePerDay}`
        );
        const cashbackCounterBaseValue = parseFloat(
          `${cashbackCounterData.baseValue}`
        );
        const cashbackCounterBaseTime = new Date(
          cashbackCounterData.baseDate
        ).getTime();

        const incPerMs = cashbackCounterIncPerDay / DAY_IN_MILLISECONDS;

        const calculateCurrentCashback = () => {
          const now = new Date().getTime();
          const time = now - cashbackCounterBaseTime - NEGATIVE_OFFSET_IN_DAYS;
          return cashbackCounterBaseValue + time * incPerMs;
        };

        setInterval(() => {
          this.setState({
            cashbackCounterValue: calculateCurrentCashback(),
          });
        }, 5000);

        cashbackCounterValue = calculateCurrentCashback();
      }

      this.setState({ cashbackCounterValue });
    } catch (e) {
      console.error('Error loading cashback counter', e);
    }
  };

  async componentDidMount() {
    this.setState({ loading: false });

    this.handleMobileHintDialog();
    this.setCounter();
    this.fetchCashbackData();
  }

  handleSearch() {
    this.setState({
      redirectToSearch: true,
    });
  }

  handleChangeQuery(query: string) {
    this.setState({
      query,
    });
  }

  handleChangeNewsletterEmail(e: any) {
    this.setState({
      newsletterEmail: e.target.value,
    });
  }

  async handleSubscribeNewsletter() {
    if (this.state.newsletterEmail.trim() === '') return;

    const response = await NewsletterManager.subscribe(
      this.state.newsletterEmail
    );

    if (response) {
      Alert.success(
        'Newsletter',
        'Vielen Dank für Ihre Anmeldung zum plusrente Newsletter!'
      );
    } else {
      Alert.error(
        'Newsletter',
        'Bitte überprüfen Sie die angegebene E-Mail Adresse.'
      );
    }

    this.setState({
      newsletterEmail: '',
    });
  }

  handleMobileHintDialog() {
    if (
      window.innerWidth < CONSTANTS.mobileScreenSize &&
      sessionStorage.getItem('isHintShowed') !== 'true'
    ) {
      return this.showHintDialog();
    }
    return;
  }

  showHintDialog() {
    Alert.once(
      'Info',
      'Speichern Sie sich das plusrente Portal auf Ihrem Startbildschirm des Smartphones und verpassen Sie kein Cashback mehr!',
      'isHintShowed',
      'true'
    );
  }

  render() {
    if (this.state.redirectToSearch)
      return <Redirect to={`/shops-und-produkte?q=${this.state.query}`} />;

    return (
      <Layout>
        <div className="Home">
          <Hero
            size="home"
            imageSrc="/assets/images/heroes/start.jpg"
            imagePositionY={0}
            rootComponent={
              this.state.cashbackCounterValue ? (
                <CashbackCounter value={this.state.cashbackCounterValue} />
              ) : (
                ''
              )
            }
          >

            <HeroSearch
              query={this.state.query}
              title="Bei über 1.200 Shops Cashback sichern"
              subTitle="und Ihr Produkt zum besten Preis!"
              placeholder="Shop oder Produkt suchen und finden..."
              buttonLabel="suchen"
              onSubmit={this.handleSearch}
              onChange={this.handleChangeQuery}
            />
          </Hero>

          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <div>
              {this.state.cashbackShops?.length > 0 && (
                <InnerLayout>
                  <h2>Die neuesten Shops</h2>
                  <Grid container spacing={2}>
                    {this.state.cashbackShops.map((shop: Shop, key) => (
                      <Grid item xs={6} md={3} key={key}>
                        <ShopTile
                          image={shop.logo}
                          buttonLabel="zum Shop"
                          morePath={`/shop/${shop.id}`}
                          buttonLink={shop.link}
                          isFavorite={shop.isFavorite}
                          onFavoriteChange={this.handleFavoriteChange(shop)}
                          shopId={shop.id}
                          history={this.props.history}
                        >
                          Bis zu{' '}
                          <strong>
                            {shop.maximumCashback} {shop.maximumCashbackType}
                          </strong>{' '}
                          bei {shop.name}
                        </ShopTile>
                      </Grid>
                    ))}
                  </Grid>
                  <div
                    style={{
                      textAlign: 'center',
                      padding: '1em',
                      paddingTop: '3em',
                    }}
                  >
                    <Link to="/shops-und-produkte">
                      <Button variant="outlined" size="large" color="primary">
                        Alle Shops anzeigen
                      </Button>
                    </Link>
                  </div>
                </InnerLayout>
              )}

              <InnerLayout
                style={{
                  backgroundColor: '#fff',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <TeaserTile
                      title="Regional Shoppen"
                      subTitle="Partner werden und neue Kunden gewinnen"
                      buttonLabel="zur Registrierung"
                      image="/assets/images/heroes/vorOrt.jpg"
                      buttonTo="https://www.diebayerische.de/regionales-shoppen"
                      variant="yellow"
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TeaserTile
                      title="FAQ"
                      subTitle="Häufig gestellte Fragen"
                      buttonLabel="zum FAQ"
                      image="/assets/images/heroes/vorOrt.jpg"
                      buttonTo="/info/faq"
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TeaserTile
                      title="Persönlicher Reiseservice mit 5% Cashback"
                      subTitle="Reiseberater oder individuell buchen"
                      buttonLabel="zum Reiseshop"
                      image="/assets/images/heroes/travel.jpg"
                      buttonTo="/reisen"
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TeaserTile
                      title="Nie wieder Cashback verpassen!"
                      subTitle="Die plusrente Browser-Extension"
                      buttonLabel="Jetzt herunterladen"
                      image="/assets/images/heroes/start.jpg"
                      buttonTo={
                        Auth.isLoggedIn() ? '/profil' : '/info/faq#cashback'
                      }
                    />
                  </Grid>
                </Grid>
              </InnerLayout>

              {this.state.specials?.length > 0 && (
                <InnerLayout>
                  <h2>Aktuelle Specials & Highlights</h2>

                  <Grid container spacing={2}>
                    {this.state.specials?.map((special: Special, key) => (
                      <Grid item xs={12} md={6} key={key}>
                        <SpecialTile
                          image={special.image}
                          title={special.title}
                          info={
                            special.end
                              ? `Nur bis zum ${helper.formatDate(special.end)}`
                              : undefined
                          }
                          buttonLabel="zum Shop"
                        >
                          {special.text}
                        </SpecialTile>
                      </Grid>
                    ))}
                  </Grid>

                  <div
                    style={{
                      textAlign: 'center',
                      padding: '1em',
                      paddingTop: '3em',
                    }}
                  >
                    <Link to="/angebote">
                      <Button variant="outlined" size="large" color="primary">
                        Alle Specials & Highlights
                      </Button>
                    </Link>
                  </div>
                </InnerLayout>
              )}

              <InnerLayout
                style={{
                  backgroundColor: '#fff',
                  paddingBottom: '10em',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TeaserTile
                      title="Jetzt bei über 1.200 Shops Cashback sichern!"
                      subTitle="Shops & Produkte zum besten Preis finden"
                      buttonLabel="Jetzt online shoppen"
                      image="/assets/images/heroes/cashback.jpg"
                      variant="primary"
                      buttonTo="/shops-und-produkte"
                    ></TeaserTile>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TeaserTile
                      variant="yellow"
                      title="Newsletter"
                      subTitle="Immer Up-to-Date mit dem plusrente-Newsletter"
                      image="/assets/images/heroes/start.jpg"
                    >
                      <InputWithButton
                        placeholder="Ihre E-Mail Adresse"
                        buttonLabel="Jetzt eintragen"
                        value={this.state.newsletterEmail}
                        onChange={this.handleChangeNewsletterEmail}
                        onClick={this.handleSubscribeNewsletter}
                      />
                    </TeaserTile>
                  </Grid>
                </Grid>
              </InnerLayout>
            </div>
          )}
        </div>
      </Layout>
    );
  }
}
